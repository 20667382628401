import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {FormikContextType, useFormik} from 'formik'
import * as _redux from '../redux/DigitalWaiverRedux'
import {AuthLoggedin, validateHash} from '../redux/DigitalWaiverCRUD'
import {AxiosError} from 'axios'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {DigitInput} from '../../../components/inputs/DigitInput'
import {QrCode} from '../../../components/utils/QrCode'
import {Button} from '../../../components/inputs/Button'
import {LoginFields} from './forms/LoginFields'
import {useFormikLogin} from '../hooks/useFormikLogin'
import {LoginHeader} from './LoginHeader'
import {LoginByTicketId} from './LoginByTIcketId'
import {OrDivider} from '../../../components/utils/OrDivider'
import {LoginType} from './MyPassLogin'
import {IS_QUICK_WAIVER} from '../../../../config/env'

export interface FormikProps {
  username: string
  password: string
  otpValue: string
}

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .min(10, 'Minimum 10 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email address is required'),
  // password: Yup.string()
  //   .min(6, 'Minimum 6 characters')
  //   .max(50, 'Maximum 50 characters')
  //   .required('Password is required'),
})

const initialValues: FormikProps = {
  username: '',
  password: '',
  otpValue: '',
}

export function Login() {
  const otpInputRef = useRef<HTMLInputElement | null>(null)
  const [mfaLink, setMfaLink] = useState<string>()
  const [authToken, setAuthToken] = useState<string>()
  const [hash, setHash] = useState<string>()
  const [loginType, setLoginType] = useState<LoginType>(
    IS_QUICK_WAIVER && IS_QUICK_WAIVER === 'TRUE' ? 'waiver' : 'normal'
  )
  const match = useRouteMatch<{hash?: string}>()
  const history = useHistory()
  const {push} = useAlerts()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const validateHashFromUrl = useCallback(
    async (hash: string) => {
      try {
        await validateHash(hash)
        push({
          message: `Please enter your username and password.`,
          timeout: 10000,
          variant: 'success',
        })
        setHash(hash)
      } catch (e) {
        push({
          message: `Invalid url. Please contact support.`,
          timeout: 10000,
          variant: 'danger',
        })
        history.replace('/auth/login')
      }
    },
    [history, push]
  )

  useEffect(() => {
    if (match.params.hash) {
      validateHashFromUrl(match.params.hash)
    }
  }, [match.params.hash, validateHashFromUrl])

  const formikSubmit = useFormikLogin({
    setMfaLink,
    setAuthToken,
    otpInputRef,
  })

  const formik: FormikContextType<FormikProps> = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus}) => {
      setLoading(true)
      setStatus('')
      if (values.otpValue && authToken) {
        formikSubmit.sendRequestByOTP(values.otpValue, formik, authToken)
      } else if (mfaLink) {
        setMfaLink(undefined)
        otpInputRef.current?.focus()
      } else {
        try {
          if (hash) {
            formikSubmit.sendRequestByHash(values.username, values.password, hash)
          } else {
            // const {data, status} = await AuthLogin(values.username, values.password)
            const {data, status} = await AuthLoggedin(values.username)
            if (status === 202) {
              setAuthToken(data.token)
              otpInputRef.current?.focus()
            } else {
              dispatch(_redux.actions.auth.login(data.token))
            }
          }
        } catch (e) {
          const responseError: AxiosError<unknown> = e
          if (responseError.response?.status) {
            if (responseError.response.status === 403) {
              setStatus('Please check your email to enable your 2FA authentication.')
            } else {
              // setStatus('Invalid username or password.')
              setStatus('Invalid username.')
            }
          } else {
            setStatus('Something went wrong. Please try again later.')
          }
        }
      }
      setLoading(false)
    },
  })

  const handleOtpChange = useCallback(
    (value: string) => {
      formik.setFieldValue('otpValue', value)
    },
    [formik]
  )

  const loginFields = useMemo(() => {
    if (!authToken) {
      return <LoginFields formik={formik} />
    }
  }, [formik, authToken])

  const otpFields = useMemo(() => {
    const isShown = Boolean(authToken && !mfaLink)
    return (
      <div className={clsx('m-5', {'d-none': !isShown})}>
        <DigitInput
          ref={otpInputRef}
          className='mt-5'
          value={formik.values.otpValue}
          onChange={handleOtpChange}
          length={OTP_LENGTH}
        />
      </div>
    )
  }, [authToken, formik.values.otpValue, handleOtpChange, mfaLink])

  const mfaQrCode = useMemo(() => {
    if (mfaLink) {
      return (
        <div className='d-flex justify-content-center mb-5'>
          <div className='p-3 d-inline-block bg-white'>
            <a href={mfaLink}>
              <QrCode value={mfaLink} />
            </a>
          </div>
        </div>
      )
    }
  }, [mfaLink])

  const handleBack = useCallback(() => {
    formik.resetForm()
    setMfaLink(undefined)
    setAuthToken(undefined)
  }, [formik])

  return (
    <>
      {loginType === 'waiver' ? (
        <LoginByTicketId isNormalLogin setLoginType={setLoginType} />
      ) : loginType === 'normal' ? (
        <>
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form card'
          >
            <LoginHeader authToken={authToken} mfaLink={mfaLink} />

            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}
            {loginFields}
            {otpFields}
            {mfaQrCode}
            <div className='text-center'>
              <button
                type='submit'
                className='btn btn-lg btn-light-primary w-100 mb-5'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Continue</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              {(authToken || mfaLink) && (
                <Button
                  type='button'
                  className='w-100'
                  onClick={handleBack}
                  disabled={formik.isSubmitting}
                >
                  Back
                </Button>
              )}
            </div>
          </form>
          {/* <Button variant='text' className='py-0 pt-3' onClick={() => setLoginType?.('waiver')}>
            Go to Quick waiver Login
          </Button> */}
        </>
      ) : null}

      {!loginType && (
        <div className='text-center'>
          <Button className='btn-primary' onClick={() => setLoginType('normal')}>
            WEB•N•TECH Login
          </Button>
          <OrDivider className='or-divider-cp mt-5' />
          <Button className='btn-primary mt-5' onClick={() => setLoginType('waiver')}>
            Quick waiver
          </Button>
        </div>
      )}
    </>
  )
}

const OTP_LENGTH = 6
